<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ translateTitleCase("userDashboard.payments.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ translateUcFirst("userDashboard.payments.introText") }}
      </p>

      <custom-crud-list v-bind="listConfiguration">
        <template #cell-amount="{item}">
          {{translateNumber (item.amount, 'currency', {currency: item.currency})}}
        </template>
        <template #cell-handler="{item}">
          {{tu(`payments.gateways.${item.handler}.label`)}}
        </template>
        <template #cell-status="{item}">
          {{tu(`payments.status.${item.status}`)}}
        </template>
      </custom-crud-list>
    </template>
  </layout-user-dashboard>
</template>

<script>
import {computed} from 'vue';
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  components: {
    CustomCrudList
  },
  layout: {
    component: "default",
    params: {
      title: "userDashboard.payments.pageTitle",
      subTitle: "userDashboard.payments.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove",
      viewTitle: "userDashboard.payments.pageTitle",
      viewExplain: "userDashboard.payments.introText",
    },
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {
      listConfiguration: {
        subject: "vendor",
        listSrc: "entity/payment",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        editRoutePrefix: "admin-", //**
        createRoutePrefix: "admin-",//**
        deleteRoutePrefix: "ecommerce/",//**
        additionalListData: {
          simplified: false,
          translate: false,
        },
        constantFilters: {
          OwnerId: computed(() => this.$store.getters['user/profile']?.id)
        },
        fields: {
          // these are the table columns
          amount: {
            itemKey: "amount",
            label: "payments.fields.amount",
          },
          handler: {
            itemKey: "handler",
            label: "userDashboard.payments.handler",
          },
          handlerId: {
            itemKey: "handlerId",
            label: "userDashboard.payments.handlerId",
          },
          status: {
            itemKey: "status",
            label: "payments.fields.status",
          },
        },
        actions: false,
      },

    };
  },
  computed: {
    userId() {
      return this.$store.getters["user/profile"].id;
    },
  },
  meta: "userDashboard.payments.browserTitle",
};
</script>

<style scoped lang="scss"></style>
